import passbookService from "../../services";

export default {
    data() {
        return {
            statement: {}
        }
    },
    mounted() {
        this.showLoader(true);
        passbookService.getStatementDetail({
            'id': this.$route.params.statementId
        }).then(response => {
            this.hideLoader();
            console.log(response.data.data)
            this.statement = response.data.data;
        });
    }

}