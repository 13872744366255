import passbookService from "../../services";

export default {
    data() {
        return {
            statement: {},
            country: {}
        }
    },
    mounted() {
        this.showLoader(true);
        passbookService.getRewardsPointStatementDetail(this.$route.params.rewardId).then(response => {
            this.hideLoader();
            this.statement = response.data;
            this.country = response.data.country;
        });
    }

}