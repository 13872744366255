<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Lifely Coins Statement Detail</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>

    </a>
</app-header>

<!-- <div class="page-title-clear"></div> -->
<div class="page-content">
    <div v-if="(Object.keys(statement).length > 0)" class="mt-5">
        <div class="pt-3 mb-4 py-4 text-center card pt-5 rewardStatusCardContainer">
            <span class="mt-3 mb-2 celebrateIcon">
                🎊
            </span>
            <p class="mb-0 mt-4 letterSpacing font-25 font-200 text-white">
                Congratulation!
            </p>
        </div>
        <div class="rewardStatusCardWrapper">
            <h4 class="font-18 letterSpacing font-200 text-white mt-3">
                <p class="mb-3 font-300 text-white" v-if="statement.transaction_type.toLowerCase() == 'debit'">
                    You spent Lifely Coins
                </p>
                <p class="mb-3 font-300 text-white" v-if="statement.transaction_type.toLowerCase() == 'credit'">
                    You earned Lifely Coins
                </p> 
                <p class="mb-3 font-28 font-500  text-white ">
                    <i class="fas fa-coins font-25 me-2"></i>
                    {{statement.amount}}
                </p>
            </h4>
        </div>

        <div class="mt-5">
            <div class="d-flex justify-content-between mb-2 pt-4">
                <h5 class="ms-4 letterSpacing font-500 mb-0">Description</h5>
                <span class="me-4 mb-0 statementCreatedAt">
                    <i class="far fa-calendar-alt me-1"></i>
                    {{statement.created_at ? localDate(statement.created_at) : ''}}
                </span>
            </div>
            <router-link :to="{name:'order-detail',params: {'id' : statement.transaction_model_id}}">
                <div class="card card-style rounded-s mb-2" style="background-color: rgb(213, 229, 254);">
                    <div class="content my-2">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="letterSpacing font-15 text-dark lh-lg font-500">
                                {{statement.description}}
                            </div>
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="card card-style mb-2 rounded-s shadow-l">
            <div class="content">
                <div class="">
                    <div class="d-flex mb-3 justify-content-between">
                        <div class="mr-3">
                            <h5 class="font-400 letterSpacing text-secondary">Opening Coin Balance</h5>
                        </div>
                        <div class="ml-auto">
                            <h4 class="letterSpacing font-400"
                                :class="[statement.opening_balance > 0 ? 'text-success' : 'text-secondary']">
                                {{statement.opening_balance }}</h4>
                        </div>
                    </div>
                    <div class="d-flex mb-3 justify-content-between">
                        <div class="mr-3">
                            <h5 class="font-400 letterSpacing text-secondary"
                                v-if="statement.transaction_type =='credit'">
                                Earned Coins
                            </h5>
                            <h5 class="font-400 letterSpacing text-secondary"
                                v-if="statement.transaction_type =='debit'">
                                Spent Coins
                            </h5>
                        </div>
                        <div class="ml-auto">
                            <h4 class="letterSpacing text-success font-400"
                                v-if="statement.transaction_type =='credit'">
                                +{{statement.amount}}
                            </h4>
                            <h4 class="letterSpacing text-danger font-400" v-if="statement.transaction_type =='debit'">
                                -{{statement.amount}}
                            </h4>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="d-flex mb-3 justify-content-between">
                        <div class="mr-3">
                            <h5 class="font-400 letterSpacing text-secondary">Closing Coin Balance</h5>
                        </div>
                        <div class="ml-auto">
                            <h4 class="letterSpacing text-success font-400">
                                {{statement.closing_balance}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<app-footer />