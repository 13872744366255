<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;" v-if="hasSuperAdmin">
        {{ full_name || 'Statements' }}
    </h1>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;" v-if="!hasSuperAdmin">
        Statements
    </h1>
    <a href="#" data-menu="searchMenu"
        :class="hasSuperAdmin ? 'header-icon header-icon-3' : 'header-icon header-icon-2'">
        <i class="fa fa-search"></i>
    </a>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <div class="header-icon header-icon-2"
        v-if="hasPermission('passbook_bonus_create') || hasPermission('passbook_penalty_create')">
        <button type="button" class="btn text-dark dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
            aria-expanded="false">
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-end"
            style="padding: 0 !important;margin: 0 !important;">
            <li class="border-bottom">
                <button class="dropdown-item letterSpacing" v-if="hasPermission('passbook_bonus_create')"
                    data-menu="bonusMenu">
                    Add Credit
                </button>
            </li>
            <li class="border-bottom">
                <button class="dropdown-item letterSpacing" v-if="hasPermission('passbook_penalty_create')"
                    data-menu="penaltyMenu">
                    Add Debit
                </button>
            </li>
            <li class="border-bottom">
                <button class="dropdown-item letterSpacing" v-if="hasPermission('passbook_bonus_create')"
                    data-menu="refundItemMenu">
                    Refund Item
                </button>
            </li>
        </ul>
    </div>
</app-header>
<div class="page-title-clear"></div>
<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div class="d-flex justify-content-evenly mt-3 mb-4" v-if="hasPermission('reward_read') && hasPermission('passbook_statement_read')">
        <button @click="currentPage = 'wallet'" class="statementPills col-5 content m-0 text-center"
            :class="[currentPage == 'wallet' ? 'currentPageActive' : 'pageInActive']">
            <h5 class="letterSpacing font-400 mb-0 font-15">Lifely Pay</h5>
        </button>
        <button @click="currentPage = 'reward_points'" class="statementPills col-5 content m-0 text-center"
            :class="[currentPage == 'reward_points' ? 'currentPageActive' : 'pageInActive']">
            <h5 class="letterSpacing font-400 mb-0 font-15">Lifely Coins</h5>
        </button>
    </div>


    <!-- NO PERMISSION PART START -->

    <div v-if="!hasPermission('reward_read') && !hasPermission('passbook_statement_read')">
        <div class="mt-5 text-center">
            <i class="fas fa-exclamation-circle font-30 text-danger mb-3"></i>
            <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                Sorry! You have no permission to access.
            </p>
        </div>
    </div>

    <!-- NO PERMISSION PART END -->

    <!-- TRANSACTION STATEMENT START -->
    <div class="mt-2" v-if="currentPage == 'wallet'">
        <div class="card card-style rounded-s walletCard">
            <div class="content d-flex justify-content-between align-items-center">
                <div class="ms-4 d-flex flex-column justify-content-between">
                    <h2 class="font-300 letterSpacing font-17 mb-0 text-white">
                        Wallet Balance
                    </h2>
                    <h2 class="font-400 letterSpacing font-23 mb-0 mt-2 text-white">
                        {{currency_symbol}}{{walletBalance ? walletBalance : '0'}}
                    </h2>
                </div>
                <div class="walletCardIconWrapper">
                    <i class="fas fa-wallet walletCardIcon scale-box font-20"></i>
                </div>
            </div>
        </div>

        <div class="content mx-1">
            <div class="d-flex justify-content-between align-items-center shadow-none rounded-s mb-1 mx-2"
                v-if="(transactionStatements.length>0)">
                <h5 class="letterSpacing font-500 text-secondary">Wallet Transactions</h5>
                <button class="btn btn-xs mb-2 letterSpacing primary-alt-base-bg"
                    @click="export">
                    <i class="fas fa-download"></i>
                    Export
                </button>
            </div>
            <div v-if="transactionStatements && transactionStatements.length > 0">
                <div v-for="(record, index) in transactionStatements" class="card card-style mb-2 mx-2 rounded-s">
                    <div class="content my-2 mx-3">
                        <router-link :to="{name:'passbook-statement-details',params: {'statementId' : record.id}}">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex flex-column justify-content-between">
                                    <p class="primary-light-text text-capitalize font-16 mb-0 font-500 letterSpacing w-100"
                                        v-if="record.transaction_type && record.transaction_type == 'credit'">
                                        {{record.statement_type ? record.statement_type : ''}}
                                    </p>
                                    <p class="primary-alt-base-text text-capitalize font-16 mb-0 font-500 letterSpacing w-100"
                                        v-if="record.transaction_type && record.transaction_type == 'debit'">
                                        {{record.statement_type ? record.statement_type : ''}}
                                    </p>
                                    <p class="text-secondary text-capitalize font-16 mb-0 font-500 letterSpacing w-100"
                                        v-if="record.transaction_type && record.transaction_type != 'debit' && record.transaction_type != 'credit'">
                                        {{record.statement_type ? record.statement_type : ''}}
                                    </p>
                                    <p class="font-12 mb-0 text-end mt-2">
                                        <i class="fas fa-clock me-1"></i>
                                        {{record.created_at ? localDate(record.created_at) : ''}}
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="">
                                        <span class="font-14 mb-1 mt-1 text-end text-capitalize letterSpacing font-600"
                                            :class="[record.transaction_type == 'credit'? 'text-success':'text-danger']">
                                            <u class="text-decoration-none"
                                                v-if="record.transaction_type && record.transaction_type == 'credit'">+</u>
                                            <u class="text-decoration-none"
                                                v-if="record.transaction_type && record.transaction_type == 'debit'">-</u>{{currency_symbol}}{{record.amount}}
                                        </span>
                                    </div>
                                    <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-else>
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Transaction Found
                </p>
            </div>
        </div>
    </div>
    <!-- TRANSACTION STATEMENT END -->


    <!-- REWARD STATEMENT START -->
    <div class="mt-2" v-if="currentPage == 'reward_points' && hasPermission('reward_read')">

        <div class="card card-style rounded-s rewardCard mt-4">
            <div class="content d-flex justify-content-between align-items-center">
                <div class="ms-4 d-flex flex-column justify-content-between">
                    <h2 class="font-300 letterSpacing font-17 mb-0 text-white">
                        Lifely Coins
                    </h2>
                    <h2 class="font-400 letterSpacing font-23 mb-0 mt-2 text-white">
                        {{currentRewardBalance ? currentRewardBalance : '0'}}
                    </h2>
                </div>
                <div class="rewardCardIconWrapper">
                    <i class="fas fa-coins rewardCardIcon font-20 scale-box"></i>
                </div>
            </div>
        </div>

        <div class="card card-style rounded-s totalRewardCard mt-4">
            <div class="content d-flex ms-4">
                <div class="crownContainer">
                    <i class="fas fa-crown font-23"></i>
                </div>
                <div class="ms-4 d-flex flex-column justify-content-between">
                    <h2 class="font-200 letterSpacing font-17 mb-0">
                        Total Lifely Coins Earned
                    </h2>
                    <h2 class="font-400 letterSpacing font-23 mb-0 mt-2 primary-light-text">
                        {{ earnedTotalRewards ? earnedTotalRewards : '0' }}
                    </h2>
                </div>
            </div>
        </div>
        <div class="content mx-1 mt-5">
            <div class="shadow-none rounded-s mb-2 ms-3" v-if="(coinsStatements.length>0)">
                <h5 class="letterSpacing font-500 text-secondary">Lifely Coins Transaction</h5>
            </div>
            <div v-if="coinsStatements && coinsStatements.length > 0">
                <div v-for="(record, index) in coinsStatements" class="card card-style mb-2 mx-2 rounded-s">
                    <div class="content my-2 mx-3">
                        <router-link :to="{name:'reward-statement-details',params: {'rewardId' : record.id}}">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex flex-column justify-content-between">
                                    <p class="text-secondary text-capitalize font-16 mb-0 font-500 letterSpacing w-100">
                                        {{record.description ? record.description : ''}}
                                    </p>
                                    <p class="font-12 mb-0 mt-2">
                                        <i class="far fa-calendar-alt me-1"></i>
                                        {{record.created_at ? localDate(record.created_at) : ''}}
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="">
                                        <span class="font-14 mb-1 mt-1 text-end text-capitalize letterSpacing font-600"
                                            :class="[record.transaction_type == 'credit'? 'text-success':'text-danger']">
                                            <u class="text-decoration-none"
                                                v-if="record.transaction_type && record.transaction_type == 'credit'">+</u>
                                            <u class="text-decoration-none"
                                                v-if="record.transaction_type && record.transaction_type == 'debit'">-</u>{{record.amount}}
                                        </span>
                                    </div>
                                    <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center" v-else>
                <i class="fas fa-search font-20 text-secondary mb-3"></i>
                <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                    No Reward Found
                </p>
            </div>
        </div>
    </div>
    <!-- REWARD STATEMENT END -->

</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search "
                v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<div id="bonusMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-500">Add Bonus</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <Form novalidate :validation-schema="bonusFormValidations" class="mt-2" @submit="submitBonusForm"
            v-slot="{ errors }" autocomplete="off">
            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.amount}">
                <i class="mt-n3 pt-1 font-16 currencyIcon ms-n1">
                    {{ currency_symbol ? currency_symbol : "" }}
                </i>
                <Field as="input" type="number" name="amount" v-model="bonus.amount" class="form-control" id="amount"
                    placeholder="Bonus Amount"></Field>
                <label for="amount" class="color-primary-dark">Bonus Amount</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.amount}}
                </template>
            </form-control>
            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.description}">
                <i class="fas fa-pen"></i>
                <Field as="textarea" type="text" name="description" v-model="bonus.description" class="form-control"
                    id="description" placeholder="Description"></Field>
                <label for="createdAt" class="color-primary-dark">Description</label>
                <template v-slot:error>
                    {{errors.description}}
                </template>
            </form-control>
            <button type="submit"
                class="btn btn-full btn-m shadow-l rounded-s font-600 primary-light-bg letterSpacing w-100">Submit</button>
        </Form>
    </div>
</div>

<div id="penaltyMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-500">Add Penalty</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <Form novalidate :validation-schema="penaltyFormValidations" class="mt-2" @submit="submitPenaltyForm"
            v-slot="{ errors }" autocomplete="off">
            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.amount}">
                <i class="mt-n3 pt-1 font-16 currencyIcon ms-n1">
                    {{ currency_symbol ? currency_symbol : "" }}
                </i>
                <Field as="input" type="number" name="amount" v-model="penalty.amount" class="form-control" id="amount"
                    placeholder="Penalty Amount"></Field>
                <label for="amount" class="color-primary-dark">Penalty Amount</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.amount}}
                </template>
            </form-control>
            <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.description}">
                <i class="fas fa-pen"></i>
                <Field as="textarea" type="text" name="description" v-model="penalty.description" class="form-control"
                    id="description" placeholder="Description"></Field>
                <label for="createdAt" class="color-primary-dark">Description</label>
                <em>(required)</em>
                <template v-slot:error>
                    {{errors.description}}
                </template>
            </form-control>
            <button type="submit"
                class="btn btn-full btn-m shadow-l rounded-s font-600 primary-light-bg letterSpacing w-100">Submit</button>
        </Form>
    </div>
</div>


<div id="refundItemMenu" class="menu menu-box-bottom rounded-m" style="background-color: rgb(238,242,241);height: 100%;"
    data-menu-effect="menu-over">
    <RefundItemPoints />
</div>

<app-footer />