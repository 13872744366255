import passbookService from "../../services";
export default {
    data() {
        return {
            hasSuperAdmin: false,
            searchedResultShow: false,
            transactionStatements: [],
            coinsStatements: [],
            currentRewardBalance: '',
            earnedTotalRewards: '',
            walletBalance: null,
            currentPage: "",
            currency_symbol: null,
            full_name: null,
            search: "",
            bonus: {
                user_id: this.$route.params.userId,
                amount: "",
                description: ""
            },
            penalty: {
                user_id: this.$route.params.userId,
                amount: "",
                description: ""
            },
            walletMeta: {},
            rewardMeta: {},
            bonusFormValidations: {
                amount: v => !!v || "Amount is required",
                description: v => !!v || "Description is required",
            },
            penaltyFormValidations: {
                amount: v => !!v || "Amount is required",
                description: v => !!v || "Description is required",
            },

        }
    },
    mounted() {
        if (this.hasPermission('reward_read') && this.hasPermission('passbook_statement_read')) {
            this.currentPage = "wallet";
        }
        if (this.hasPermission('reward_read')) {
            this.currentPage = 'reward_points';
            this.getRewardsPointData();
            this.getRewardBalance();
        }
        if (this.hasPermission('passbook_statement_read')) {
            this.currentPage = 'wallet';
            this.getTransactionData();
        }
        this.getNextRecord();


    },
    created() {
        if ('user' in this.$store.state && this.$store.state.user != null && this.$store.state.user.user_type == 'super_admin') {
            this.hasSuperAdmin = true;
        }
    },
    methods: {
        getTransactionData() {
            this.showLoader(true);
            passbookService.getPassbookStatments({
                "user_id": this.$route.params.userId,
                "search": this.search
            }).then(response => {
                this.hideLoader();
                this.walletBalance = response.data.balance;
                this.currency_symbol = response.data.currency_symbol;
                this.transactionStatements = response.data.data;
                if (this.transactionStatements.length > 0) {
                    this.full_name = response.data.data[ 0 ].user
                }
                this.walletMeta = response.data.meta;
            });
        },

        getRewardsPointData() {
            this.showLoader(true);
            passbookService.getRewardsPointStatements({
                "user_id": this.$route.params.userId,
                "search": this.search
            }).then(response => {
                this.hideLoader();
                this.coinsStatements = response.data.data;
                this.rewardMeta = response.data.meta;
            })
        },

        getRewardBalance() {
            passbookService.getRewardBalance({
                "user_id": this.$route.params.userId,
                "search": this.search
            }).then(response => {
                this.hideLoader();
                this.currentRewardBalance = response.data.amount;
                this.earnedTotalRewards = response.data.total_earned;
            })

        },

        export() {
            this.showLoader(true);
            passbookService.export({
                "user_id": this.$route.params.userId,
                "search": this.search,
                "export": true
            }).then(response => {
                this.hideLoader();
                this.generatePDF(response.data, this.full_name + "_passbook_report");
            });
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (this.hasPermission('passbook_statement_read') && this.currentPage == 'wallet' && bottomOfWindow && this.walletMeta.current_page != this.walletMeta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    passbookService.getPassbookStatments({
                        "user_id": this.$route.params.userId,
                        "search": this.search,
                        'page': this.walletMeta.current_page + 1
                    }).then(response => {
                        this.hideLoader();
                        this.transactionStatements.push(...response.data.data);
                        this.walletMeta = response.data.meta;
                        this.loading = false;
                    })
                }
                if (this.hasPermission('reward_read')
                    && this.currentPage == 'reward_points'
                    && bottomOfWindow && this.rewardMeta.current_page != this.rewardMeta.last_page
                    && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    passbookService.getRewardBalance({
                        "user_id": this.$route.params.userId,
                        "search": this.search,
                        'page': this.rewardMeta.current_page + 1
                    }).then(response => {
                        this.hideLoader();
                        this.coinsStatements.push(...response.data.data);
                        this.currentRewardBalance = response.data.amount;
                        this.rewardMeta = response.data.meta;
                        this.loading = false;
                    })
                }
            }
        },
        submitBonusForm() {
            this.showLoader(true);
            passbookService.addBonus(this.bonus).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
                this.getTransactionData();
                let menuCLose = document.getElementsByClassName('close-menu')[ 0 ];
                menuCLose.click();
            });
        },
        submitPenaltyForm() {
            this.showLoader(true);
            passbookService.addPenalty(this.penalty).then(response => {
                this.hideLoader();
                this.$popper.success(response.data.message)
                this.getTransactionData();
                let menuCLose = document.getElementsByClassName('close-menu')[ 0 ];
                menuCLose.click();
            });
        },
        filterUpdate() {
            if (this.hasPermission('passbook_statement_read')) {
                this.walletMeta.current_page = 1;
                this.getTransactionData();
            }
            if (this.hasPermission('reward_read')) {
                this.rewardMeta.current_page = 1;
                this.getRewardsPointData();
            }
            this.searchedResultShow = true;
        },
        filterClear() {
            this.search = "";
            if (this.hasPermission('passbook_statement_read')) {
                this.walletMeta.current_page = 1;
                this.getTransactionData();
            }
            if (this.hasPermission('reward_read')) {
                this.rewardMeta.current_page = 1;
                this.getRewardsPointData();
            }
            this.searchedResultShow = false;
        },
    }
}