<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Passbook Statement Detail</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>
<div class="page-content">
    <div v-if="(Object.keys(statement).length > 0)">
            <div class="pt-3 text-center mb-4">
                <div v-if="statement.statement_type.toLowerCase() == 'penalty'">
                    <i class="fas fa-exclamation-triangle fa-4x text-danger"></i>
                    <h4 class="font-18 letterSpacing font-500 mt-3 text-danger">{{statement.statement_type}}</h4>
                </div>
                <div v-if="statement.statement_type.toLowerCase() == 'bonus'">
                    <i class="fas fa-donate fa-5x text-success"></i>
                    <h4 class="font-18 letterSpacing font-500 mt-3 text-success">{{statement.statement_type}}</h4>
                </div>
            </div>
        <div class="">
            <div class="d-flex justify-content-between mb-2">
                <h5 class="ms-4 letterSpacing font-500 mb-0">Description</h5>
                <span class="me-4 mb-0 statementCreatedAt">
                    <i class="fas fa-clock"></i>
                    {{statement.created_at ? localDate(statement.created_at) : ''}}
                </span>
            </div>
            <div class="card card-style rounded-s shadow-l mb-3">
                <div class="content my-2">
                    <div class="letterSpacing font-15 text-dark lh-lg">
                        {{statement.description}}
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-style mb-2 rounded-s shadow-l">
            <div class="content">
                <div class="d-flex">
                    <i class="fas fa-arrow-circle-down scale-box fa-2x text-secondary"></i>
                    <div class="ms-3">
                        <h4 class="mb-0 letterSpacing font-500 font-16">Opening Balance</h4>
                    </div>
                    <div class="ms-auto">
                        <h5 class="mb-n1">{{statement.currency_symbol}} {{statement.opening_balance ?
                            statement.opening_balance : ''}}</h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-style mb-2 rounded-s shadow-l">
            <div class="content">
                <div class="d-flex">
                    <i class="fas fa-wallet scale-box fa-2x text-secondary"></i>
                    <div class="ms-3">
                        <h4 class="mb-0 letterSpacing font-500 font-16">Transaction Amount</h4>
                    </div>
                    <div class="ms-auto">
                        <h5 class="mb-n1 text-success" v-if="statement.transaction_type =='credit'">
                            +{{statement.currency_symbol}} {{statement.amount ? statement.amount : ''}}
                        </h5>
                        <h5 class="mb-n1 text-danger" v-if="statement.transaction_type =='debit'">
                            -{{statement.currency_symbol}} {{statement.amount ? statement.amount : ''}}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-style mb-2 rounded-s shadow-l">
            <div class="content">
                <div class="d-flex">
                    <i class="fas fa-arrow-circle-up scale-box fa-2x text-secondary"></i>
                    <div class="ms-3">
                        <h4 class="mb-0 letterSpacing font-500 font-16">Closing Balance</h4>
                    </div>
                    <div class="ms-auto">
                        <h5 class="mb-n1 text-success">{{statement.currency_symbol}} {{statement.closing_balance ?
                            statement.closing_balance : ''}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer />